import React, { useState } from 'react';
import AdminHeader from "../shared/AdminHeader";
import AdminSidebar from "../shared/AdminSidebar";
import { classNames } from "../../helpers/classNames";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="fixed top-0 left-0 w-full h-14 flex z-40">
          <AdminHeader isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen}/>
        </div>
        <div className="flex-shrink flex-grow flex pt-14">
          <div className={classNames(
            "fixed w-60 h-screen z-30 top-0 left-0 flex shadow-[2px_2px_4px_0px_rgba(32,81,109,0.08)] transition-all duration-200 ease-in-out pt-14",
            !isMenuOpen?"-translate-x-full":"translate-x-0"
          )}>
            <AdminSidebar/>
          </div>
          <div className={classNames(
            "flex w-full transition-all duration-200 ease-in-out",
            !isMenuOpen?"pl-0":"pl-60"
          )}>
            <div className="w-full bg-opplio-silver h-screen max-h-[calc(100vh-3.5rem)] overflow-auto scrollbar flex"><Outlet /></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;