import React, { useState } from 'react';
import { classNames } from "../../helpers/classNames";


const Search = ({
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  iconPosition = "left",
  icon = "fa-light fa-magnifying-glass"
}) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <>
      <div className={classNames(
        "relative flex h-10 w-full bg-white rounded-md overflow-hidden border transition-all duration-200",
        isFocused ? "border-opplio-graySky" : "border-opplio-border",
        divClasses
      )}>
        {iconPosition === "left" &&
          <div className={classNames("w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-opplio-grayB rounded-md", iconClasses)}>
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        }
        <input
          type="text"
          placeholder={placeholder}
          className={classNames(
            "flex-shrink !border-0 w-full !ring-0 bg-transparent text-opplio-gray placeholder:text-opplio-grayB text-sm p-0 px-3 font-medium focu",
            inputClasses, iconPosition === "left" ? "!pl-0" : iconPosition === "right" ? "!pr-0" : ""
          )}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {iconPosition === "right" &&
          <div className={classNames("w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-opplio-grayB rounded-md", iconClasses)}>
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        }
      </div>
    </>
  );
};

export default Search;