import React from 'react';
import Image from "../../elements/Image";
import UserImage from '../../../assets/images/member-01.webp';
import Button from "../../form/Button";

const UserTab = () => {
  return (
    <>
      <div className="relative space-y-6">
        <div className="relative bg-violet-100 py-4 px-4 rounded-lg space-y-4">
          <div className="overflow-hidden rounded-lg">
            <Image alt="" width={200} height={200} className="h-full w-full object-cover" src={UserImage} />
          </div>
          <div className="relative">
            <div className="text-lg font-semibold text-violet-700">Savanah Pippen</div>
            <div className="text-sm font-medium text-slate-500">Founder</div>
            <div className="text-sm font-medium text-slate-500">President</div>
          </div>
          <div className="relative">
            <Button
              buttonIcon={'fa-regular fa-edit'}
              buttonIconPosition={'left'}
              buttonLabel={'Edit Profile'}
              buttonClasses={'!bg-violet-500 !text-white hover:!bg-violet-600'}
            />
          </div>
        </div>
        <div className="relative space-y-2">
          <div className="text-sm text-slate-600 font-medium">Contact</div>
          <div className="relative flex items-start gap-3">
            <div className="flex-shrink-0 flex items-center justify-center text-slate-400">
              <i className="fa-regular fa-fw fa-envelope"></i>
            </div>
            <div className="flex-shrink flex-grow space-y-1">
              <div className="text-sm !leading-none font-semibold text-slate-600">Email</div>
              <div className="text-sm !leading-none text-slate-500">
                <a href="mailto:sample-email@orgchart.com" className="text-violet-500">sample-email@orgchart.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTab;