import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from "../../components/form/Button";
import Logo from "../../assets/images/logo.png";
import UserDropdown from "./UserDropdown";
import NotificationDropdown from "./NotificationDropdown";
import Search from "../../components/form/Search";
import Image from "../../components/elements/Image";

const AdminHeader = ({ isMenuOpen, setMenuOpen }) => {

  return (
    <>
      <header className="relative w-full bg-white shadow flex items-center px-6">
        <div className="mr-auto">
          <div className="flex items-center gap-4">
            {/* <Button
              buttonHasLink={false}
              buttonIconPosition={'left'}
              buttonIcon={'fa-solid fa-sharp fa-grid-round'}
              buttonClasses={'!px-0 w-10 !rounded-lg !text-2xl !bg-white !text-opplio-graySky !border-opplio-line !shadow !flex !items-center !justify-center hover:!shadow-md'}
              buttonFunction={() => setMenuOpen(!isMenuOpen)}
            /> */}
            <Link to="/" className="h-8">
              <Image
                src={Logo}
                alt={"Logo"}
                effect={"blur"}
                className={"!w-auto !h-8 !rounded-none"}
              />
            </Link>
          </div>
        </div>
        <div className="ml-auto">
          <div className="flex items-center gap-4">
            <div className="relative w-80">
              <Search
                placeholder={"Search"}
                divClasses={"!bg-slate-100 !h-8"}
                inputClasses={"!h-8"}
                iconClasses={"!w-8 !h-8"}
              />
            </div>
            <div className="relative">
              <NotificationDropdown xPlacement={'bottomRight'} />
            </div>
            <div className="relative">
              <UserDropdown xPlacement={'bottomRight'} />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AdminHeader;