import React from "react";
import { Link } from 'react-router-dom';
import { classNames } from "../../helpers/classNames";

const Button = ({
  buttonEffect = "",
  buttonClasses,
  buttonType = "button",
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction = () => { },
  buttonHasLink,
  buttonLink,
  isDisable = false,
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={classNames(
            "flex items-center space-x-2 text-sm sm:text-base text-white h-10 px-3 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-opplio-grayBlue bg-opplio-grayBlue shadown-none",
            buttonClasses,
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
        >
          {buttonIconPosition === "left" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
          {buttonLabel && (
            <span
              className={classNames(
                "text-sm font-medium block !leading-none",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={classNames(
            "flex items-center space-x-2 text-sm sm:text-base text-slate-800 h-10 px-3 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-slate-200 bg-white shadown-none",
            buttonClasses,
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
          onClick={buttonFunction}
          disabled={isDisable}
        >
          {buttonIconPosition === "left" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
          {buttonLabel && (
            <span
              className={classNames(
                "text-sm font-medium block !leading-none",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
