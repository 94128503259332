import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Image from "../../components/elements/Image";

import Member01 from "../../assets/images/member-01.webp";
import Member02 from "../../assets/images/member-02.webp";
import Member03 from "../../assets/images/member-03.webp";
import Member04 from "../../assets/images/member-04.webp";

const NotificationDropdown = ({ xPlacement, ...props }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const dropdownData = [
    {
      _id: 1,
      user: "Sujan Dey",
      initials: "SD",
      image: Member01,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: true
    },
    {
      _id: 2,
      user: "Sujay Mondal",
      initials: "SM",
      image: Member02,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: true
    },
    {
      _id: 3,
      user: "Subhajit Saha",
      initials: "SS",
      image: Member03,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: true
    },
    {
      _id: 4,
      user: "Chinmoy Ghosh",
      initials: "CG",
      image: Member04,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: true
    },
    {
      _id: 5,
      user: "Soumya Ghosh",
      initials: "SG",
      image: Member01,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: true
    },
    {
      _id: 6,
      user: "Suvadeep Biswas",
      initials: "SB",
      image: Member02,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: false
    },
    {
      _id: 7,
      user: "Ayanesh Sarkar",
      initials: "AS",
      image: Member03,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: false
    },
    {
      _id: 8,
      user: "Sanket Paul",
      initials: "SP",
      image: Member04,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: false
    },
    {
      _id: 9,
      user: "Sujan Dey",
      initials: "SD",
      image: Member01,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: false
    },
    {
      _id: 10,
      user: "Sujay Mondal",
      initials: "SM",
      image: Member02,
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      date: "16 Nov 2022",
      link: "/",
      isUnread: false
    },
  ]

  console.log("Member01:", Member01);

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-10 h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                {dropdownData.filter((item) => (item.isUnread)).length > 0 &&
                  <div className="absolute top-1 right-1 z-10 bg-violet-500 text-white text-[9px] font-semibold w-4 h-4 rounded-full flex justify-center items-center leading-none">{dropdownData.filter((item) => (item.isUnread)).length}</div>
                }
                <div className="text-xl text-slate-400">
                  <i className="fa-light fa-fw fa-bell"></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-96 origin-top-right bg-white border-0 divide-y divide-slate-200 rounded-md shadow ring-0">
                <div className="relative px-3 py-2 text-base font-bold text-slate-800">Notification</div>
                <div className="py-1 max-h-96 divide-y divide-slate-200 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item) => (
                    <Link
                      to={item.link}
                      className={classNames(
                        "group flex py-2 px-3 gap-3 transition-all duration-200",
                        item.isUnread ? 'bg-violet-50' : 'hover:bg-violet-50/50'
                      )}
                      key={item._id}
                    >
                      <div className="w-10 flex-shrink-0 mb-auto aspect-square rounded-full">
                        <Image
                          src={item.image}
                          alt={item.user}
                          effect={"blur"}
                          className={classNames("!w-10 !h-10 ring-1 ring-offset-2 rounded-full", item.isUnread ? 'ring-green-500' : 'ring-slate-200')}
                        />
                      </div>
                      <div className="w-full flex-shrink relative flex flex-col gap-2">
                        <div className="text-sm font-bold text-slate-800 leading-tight">{item.user}</div>
                        <div className="text-xs text-slate-600 leading-tight tracking-normal">{item.text}</div>
                        <div className={"text-[11px] leading-tight text-slate-400 tracking-normal"}>{item.date}</div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="relative px-3 py-3 flex justify-center items-center">
                  <Menu.Item>
                    <Link href={'/notification'} className="text-sm font-semibold text-slate-500 hover:text-violet-500 hover:underline underline-offset-1 transition-all duration-200">See all notifications</Link>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default NotificationDropdown;