import React, { useEffect, useState } from 'react';
import { classNames } from "../../helpers/classNames";

const Tabs = ({tabs, ...props}) => {
  const [selectedTab, setSelectedTab] = useState({})
  
  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if(filteredTab){
        activeTab = [...activeTab.filter((actab) => actab !== filteredTab._id), tab?._id]
      } else {
        activeTab = [...activeTab, tab?._id]
      }
      setSelectedTab(tab);
    }, 100)
  }

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs[0])
    }
  }, [tabs]);
  
  return (
    <>
      <div className="relative">
        <nav className={classNames("flex overflow-auto border-b border-slate-200 gap-4")}>
          {tabs.map((item, index) => (
            <button 
              type="button" 
              className={classNames(
                "relative py-2 transition-all duration-200 border-b-2 border-transparent px-0",
                (item._id === selectedTab?._id) ? "!border-violet-500" :"bg-late-200",
                
              )} 
              key={index} 
              onClick={() => handleTabSelection(item)}
            >
              <div className={classNames(
                "whitespace-nowrap text-sm font-semibold",
                (item._id === selectedTab?._id)?"!text-viborder-violet-500":"text-black"
              )}>{item.name}</div>
            </button>
          ))}
        </nav>
        <div className="relative bg-white py-4">{selectedTab?.component}</div>
      </div>
    </>
  );
};

export default Tabs;