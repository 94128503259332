import React, { useState } from 'react';
import Input from "../../form/Input";
import Button from "../../form/Button";
import ImageUploadSingle from "../../form/ImageUploadSingle";

const EditRoleTab = () => {
  const [image, setImage] = useState();

  return (
    <>
      <div className="relative space-y-6">
        <div className="relative">
          <Input
            label={"Job Title"}
          />
        </div>
        <div className="relative space-y-2">
          <div className="flex items-center justify-between">
            <div className="text-sm text-slate-600 font-medium">Employee</div>
            <div className="flex items-center gap-1">
              <Button
                buttonIcon={'fa-regular fa-pencil'}
                buttonIconPosition={'left'}
                buttonClasses={"!rounded-full !p-0 h-6 !text-xs !aspect-square !text-slate-400 border-none hover:!bg-slate-100"}
              />
              <Button
                buttonIcon={'fa-regular fa-xmark'}
                buttonIconPosition={'left'}
                buttonClasses={"!rounded-full !p-0 h-6 !text-sm !aspect-square !text-slate-400 border-none hover:!bg-slate-100"}
              />
            </div>
          </div>
          <ImageUploadSingle data={image} setData={setImage} icon={"fa-regular fa-image"} title={"Choose a file or drag & drop it here"} note={"JPG or PNG formats, upto 5MB"} />
        </div>
        <div className="relative">
          <Input
            label={"Full Name"}
          />
        </div>
        <div className="flex items-start gap-4">
          <div className="relative flex-grow flex-shrink">
            <Input
              label={"Office phone"}
            />
          </div>
          <div className="relative flex-shrink-0 w-16">
            <Input
              label={"Ext."}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRoleTab;