import React from 'react';
import Button from "../form/Button";
import { classNames } from "../../helpers/classNames";
import Tabs from "../elements/Tabs";
import EditRoleTab from "./TabContent/EditRoleTab";
import UserTab from "./TabContent/UserTab";

const NodeDetailsPanel = ({ isSidepanelOpen, setSidePanelOpen }) => {
  return (
    <>
      <div className={classNames("fixed top-14 right-0 w-96 h-[calc(100vh-56px)] bg-white shadow-2xl flex flex-col transition-all duration-300 !m-0", isSidepanelOpen ? "translate-x-0" : "translate-x-full")}>
        <div className="flex flex-col flex-grow">
          <div className="h-16 w-full border-b border-slate-200 flex items-center py-2 px-4 flex-shrink-0">
            <Button
              buttonHasLink={false}
              buttonIcon={'fa-regular fa-arrow-left'}
              buttonIconPosition={'left'}
              buttonFunction={() => setSidePanelOpen(false)}
            />
          </div>
          <div className="flex-grow h-[calc(100vh-64px)] overflow-auto py-6 px-6">
            <Tabs
              tabs={[
                { _id: 1, name: "Edit Role", component: <EditRoleTab/>},
                { _id: 2, name: "Savanah Pippen", component: <UserTab/>},
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NodeDetailsPanel;