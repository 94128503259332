import React, { useState } from 'react';
import SidebarBlock from "./SidebarBlock";
import Button from "../../components/form/Button";

const AdminSidebar = () => {
  const pageData1 = [
    { _id: 1, name: "Home", icon: "fa-light fa-house", link: "/", isActive: false },
    { _id: 2, name: "Directory", icon: "fa-light fa-folder-tree", link: "/", isActive: false },
    { _id: 3, name: "Company", icon: "fa-light fa-buildings", link: "/", isActive: false },
    { _id: 4, name: "Org Chart", icon: "fa-light fa-sitemap", link: "/", isActive: false },
    { _id: 5, name: "Calendar", icon: "fa-light fa-calendar", link: "/", isActive: false },
  ]

  const pageData2 = [
    { _id: 1, name: "Onboarding", icon: "fa-light fa-gauge-high", link: "/w/1/projects", },
    { _id: 2, name: "Reports", icon: "fa-light fa-chart-line-up-down", link: "/w/1/clients", },
  ];

  const optionPageData = [
    { _id: 1, name: "Settings", icon: "fa-light fa-cog", link: "/w/1/projects", },
    { _id: 2, name: "Help", icon: "fa-light fa-question-circle", link: "/w/1/clients", },
  ];



  return (
    <>
      <aside className="w-full h-screen max-h-[calc(100vh-4rem)] py-0 px-4 overflow-auto scrollbar flex">
        <div className="w-full divide-y divide-slate-200 flex flex-col">
          <SidebarBlock
            title="Pages"
            data={pageData1}
          />
          <SidebarBlock
            title="Analysis"
            data={pageData2}
          />
          <div className="mt-auto">
            <SidebarBlock
              title="Options"
              data={optionPageData}
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default AdminSidebar;