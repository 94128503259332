import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from "../../helpers/classNames";
import Dropdown from "../../components/form/Dropdown";

const NavigationItem = ({ data }) => {
  
  return (
    <>
      <div className={classNames(
        "group relative flex items-center justify-between transition-all duration-200 ease-in-out rounded-md pr-2 hover:bg-violet-100 hover:text-violet-500",
        //router.asPath === data.link ? "border-opplio-lightBlue bg-opplio-veryLightBlue text-opplio-blue" : "border-transparent bg-transparent text-opplio-grayW hover:border-opplio-lightBlue hover:bg-opplio-veryLightBlue hover:text-opplio-blue"
      )}>
        <Link
          to={data.link}
          className={classNames(
            "flex items-center w-full h-10 gap-2 px-2 text-base flex-shrink",
          )}
        >
          <i className={classNames("fa-fw", data.icon)}></i>
          <span className="text-sm">{data.name}</span>
        </Link>
      </div>
    </>
  );
};

export default NavigationItem;