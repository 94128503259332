import React, { useState } from "react";
import { v4 } from "uuid";
import Tree from "react-d3-tree"; // Direct import of Tree in React
import NodeModal from "../components/NodeModal";
import Button from "../components/form/Button";
import { classNames } from "../helpers/classNames";
import NodeDetailsPanel from "../components/shared/NodeDetailsPanel";

// Breadth-first search (bfs) function
export function bfs(id, tree, node) {
  const queue = [];
  queue.unshift(tree);

  while (queue.length > 0) {
    const curNode = queue.pop();
    if (curNode.attributes?.id === id) {
      curNode.children.push(node);
      return { ...tree };
    }

    const len = curNode.children.length;
    for (let i = 0; i < len; i++) {
      queue.unshift(curNode.children[i]);
    }
  }
}

export default function Home() {
  const [isSidepanelOpen, setSidePanelOpen] = useState(false);
  const [tree, setTree] = useState({
    name: "Savanah Pippen",
    role: "President",
    position: "Founder",
    theme:'purple',
    image:
      "https://s3-alpha-sig.figma.com/img/06d5/c815/fcb46e0253f262fd43f284820a5b7ee4?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VpAf0wHI0RqqfA9LW9zQaOB2gEVnlCbJVu9cGD7ejq21tM32qiB7xACYcGkxvXz2xbJMJUykgA5Ewb25Pw0voyoZcdqiapjwzkQFSu22B66M6GDpfrlQMuX9Abfe9bIrDU4N3ZIScv8jWvuoI2B1SCJ~G7tVYCweXM7A7xNeakb07kjDAulIfszVRomK-C0afBXG0zMrLVHwMOF~ffaK7teQWTAKiKViia3r03iZ63IEyfz2l8XJv3V4FScpANX6UzIJKL-7TlXcoUHm6yhV03i5qB0-TJUHLZ9i0q6dZSiCY9frWWzzwrbeSzm0bBaqWxT3fsX7g7Szn2iRKdEAgA__",
    attributes: {
      id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f",
    },
    children: [
      {
        name: "James Milner",
        role: "Chief Executive",
        position: "Chief Executive Owner",
        theme: 'teal',
        attributes: {
          id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f2",
        },
        children: [
          {
            name: "Carl Anderson",
            role: "Technology",
            position: "Chief Technology Owner",
            theme: 'amber',
            attributes: {
              id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f3",
            },
            children: [
              {
                name: "S. Chandler",
                role: "Technology",
                position: "Head of mobile",
                theme: 'brick',
                attributes: {
                  id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f4",
                },
                children: [],
              },
              {
                name: "P. Williamson",
                role: "Technology",
                position: "Head of mobile",
                theme: 'green',
                attributes: {
                  id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f5",
                },
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "Jack Anderson",
        role: "Chief Executive",
        position: "Chief Executive Owner",
        theme: 'teal',
        attributes: {
          id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f6",
        },
        children: [
          {
            name: "Melissa Nakamura",
            role: "Marketing",
            position: "Chief Marketing Owner",
            theme: 'navy',
            attributes: {
              id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f7",
            },
            children: [
              {
                name: "O. Lopez",
                role: "Marketing",
                position: "Head of Marketing Data",
                theme: 'amber',
                attributes: {
                  id: "411d9783-85ba-41e5-a6a3-5e1cca3dsccs294f7",
                },
                children: [
                  {
                    name: "K. Jackson",
                    role: "Marketing",
                    position: "Social Media",
                    theme: 'brick',
                    attributes: {
                      id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f7",
                    },
                    children: [],
                  },
                  {
                    name: "J. Brown",
                    role: "Marketing",
                    position: "Android  Software Engineer",
                    theme: 'green',
                    attributes: {
                      id: "411d9783-85ba-41e5-a6a3-5e1dvdvcca3d294f7",
                    },
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            name: "Taylor Watford",
            role: "Finance",
            position: "Chief Finance Owner",
            theme: 'green',
            attributes: {
              id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f7",
            },
            children: [
              {
                name: "N. Jones",
                role: "Finance",
                position: "Product Design Senior",
                theme: 'navy',
                attributes: {
                  id: "411d9783-85ba-41e5-a6a3-5e1cca3d294f7",
                },
                children: [],
              },
            ],
          },
        ],
      },
    ],
  });

  const [node, setNode] = useState();

  const close = () => setNode(undefined);

  const handleNodeClick = (datum) => {
    setNode(datum);
  };

  const handleSubmit = (familyMemberName) => {
    const newTree = bfs(node.attributes?.id, tree, {
      name: familyMemberName,
      attributes: {
        id: v4(),
      },
      children: [],
    });

    if (newTree) {
      setTree(newTree);
    }

    setNode(undefined);
  };

  // Custom render function for each node as a styled card
  const renderRectSvgNode = (customProps) => {
    const { nodeDatum } = customProps;
    const cardWidth = 264;
    const cardHeight = 119;
    const padding = 10;

    return (
      <>
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" /> 
            <feOffset dx="0" dy="5" result="offsetblur" /> 
            <feFlood flood-color="rgba(0, 0, 0, 0.08)" /> 
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode /> 
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g transform={`translate(${-cardWidth / 2}, ${-cardHeight / 2})`} className={nodeDatum.theme} filter="url(#shadow)">
          <path
            d="M1.05 26C1.05 11.8063 12.5563 0.3 26.75 0.3H237.25C251.444 0.3 262.95 11.8063 262.95 26V93C262.95 107.194 251.444 118.7 237.25 118.7H26.75C12.5563 118.7 1.05 107.194 1.05 93V26Z"
            stroke="#D4D4D4"
            strokeWidth="0.6"
            className="node-bg"
          />
          <g>
            <path
              d="M1.5 25.8875C1.5 11.9699 12.7824 0.6875 26.7 0.6875H237.3C251.218 0.6875 262.5 11.9699 262.5 25.8875V29.5H1.5V25.8875Z"
              strokeWidth="0"
              className="node-header"
            />
            <text
              x={cardWidth / 2}
              y={21}
              textAnchor="middle"
              fill="#fff"
              strokeWidth="0"
              fontFamily="Montserrat"
              fontSize={11}
              fontWeight={500}
            >
              {nodeDatum.role}
            </text>
          </g>
          <g transform="translate(11.25, 39)">
            <rect
              width="62"
              height="56.5"
              rx="16"
              fill="#D9D9D9"
              stroke="#D4D4D4"
              strokeWidth="0.6"
            />
            <image
              width="62"
              height="56.5"
              preserveAspectRatio="xMidYMid slice"
              href={'https://preview.keenthemes.com/metronic-v4/theme_rtl/assets/pages/media/profile/profile_user.jpg'}
              clip-path="inset(0 round 16px)"
            />
            <g transform="translate(70, 20)">
              <text
                x="0"
                y="0"
                textAnchor="start"
                fill="#000"
                strokeWidth="0"
                fontFamily="Montserrat"
                fontSize={13}
                fontWeight={600}
              >
                {nodeDatum.name}
              </text>
              <text
                x="0"
                y="15"
                textAnchor="start"
                fill="#000"
                strokeWidth="0"
                fontFamily="Montserrat"
                fontSize={11}
                fontWeight={400}
              >
                {nodeDatum.position}
              </text>
              <g>
                <g className="hoverable" onClick={() => setSidePanelOpen(true)}>
                  <rect x="0" y="30" width="19.0961" height="18.2431" rx="4.73846" fill="white" strokeWidth="0.4" stroke="#D2D2D2" />
                  <g transform="translate(-82, -59)">
                    <path d="M93.1821 93.8488L96.4941 99.3653C96.9701 100.158 96.7363 101.2 95.9717 101.693C95.7151 101.859 95.4159 101.948 95.1102 101.948H88.4855C87.5855 101.948 86.8554 101.192 86.8554 100.258C86.8554 99.9426 86.9409 99.6337 87.1016 99.3653L90.4142 93.8488C90.8896 93.0564 91.895 92.8138 92.6596 93.307C92.8712 93.4435 93.0501 93.6288 93.1821 93.8488ZM92.0848 94.2634C92.0243 94.2245 91.9565 94.1983 91.8856 94.1863C91.8146 94.1743 91.742 94.1768 91.672 94.1937C91.6021 94.2105 91.5363 94.2414 91.4786 94.2844C91.4209 94.3274 91.3726 94.3816 91.3365 94.4439L88.0244 99.9614C87.9706 100.051 87.9422 100.154 87.9423 100.259C87.9423 100.57 88.1855 100.822 88.486 100.822H95.1097C95.2115 100.822 95.3109 100.793 95.3969 100.737C95.5203 100.655 95.6073 100.529 95.6398 100.384C95.6723 100.24 95.6478 100.088 95.5713 99.9614L92.2593 94.4439C92.2158 94.3712 92.1563 94.3095 92.0853 94.2634H92.0848ZM91.7981 99.9712C91.667 99.9712 91.5413 99.9192 91.4486 99.8265C91.3559 99.7338 91.3038 99.6081 91.3038 99.477C91.3038 99.3459 91.3559 99.2202 91.4486 99.1275C91.5413 99.0348 91.667 98.9827 91.7981 98.9827C91.9292 98.9827 92.0549 99.0348 92.1476 99.1275C92.2403 99.2202 92.2924 99.3459 92.2924 99.477C92.2924 99.6081 92.2403 99.7338 92.1476 99.8265C92.0549 99.9192 91.9292 99.9712 91.7981 99.9712ZM91.7981 95.5228C91.9292 95.5228 92.0549 95.5749 92.1476 95.6676C92.2403 95.7603 92.2924 95.886 92.2924 96.0171V97.9942C92.2924 98.1253 92.2403 98.251 92.1476 98.3437C92.0549 98.4364 91.9292 98.4884 91.7981 98.4884C91.667 98.4884 91.5413 98.4364 91.4486 98.3437C91.3559 98.251 91.3038 98.1253 91.3038 97.9942V96.0171C91.3038 95.886 91.3559 95.7603 91.4486 95.6676C91.5413 95.5749 91.667 95.5228 91.7981 95.5228Z" fill="#D2D2D2" strokeWidth="0" />
                  </g>
                </g>
                <g className="hoverable" onClick={(e) => setNode(nodeDatum)}>
                  <rect x="25" y="30" width="19.0961" height="18.2431" rx="4.73846" fill="white" strokeWidth="0.4" stroke="#D2D2D2" />
                  <g transform="translate(-82, -59)">
                    <path d="M120.441 95.2875H116.246L115.985 94.5496C115.947 94.4418 115.876 94.3485 115.782 94.2825C115.689 94.2165 115.577 94.1812 115.463 94.1812H112.697C112.551 94.1812 112.41 94.2395 112.306 94.3433C112.202 94.447 112.144 94.5877 112.144 94.7344V100.266C112.144 100.412 112.202 100.553 112.306 100.657C112.41 100.76 112.551 100.819 112.697 100.819H120.441C120.588 100.819 120.728 100.76 120.832 100.657C120.936 100.553 120.994 100.412 120.994 100.266V95.8406C120.994 95.6939 120.936 95.5532 120.832 95.4495C120.728 95.3458 120.588 95.2875 120.441 95.2875ZM117.028 94.1812H120.441C120.881 94.1812 121.303 94.3561 121.614 94.6673C121.925 94.9784 122.1 95.4005 122.1 95.8406V100.266C122.1 100.706 121.925 101.128 121.614 101.439C121.303 101.75 120.881 101.925 120.441 101.925H112.697C112.257 101.925 111.835 101.75 111.524 101.439C111.213 101.128 111.038 100.706 111.038 100.266V94.7344C111.038 94.2943 111.213 93.8722 111.524 93.561C111.835 93.2498 112.257 93.075 112.697 93.075H115.463C116.185 93.075 116.8 93.5369 117.028 94.1812Z" fill="#D2D2D2" strokeWidth="0" />
                  </g>
                </g>
                {/* <g className="hoverable" onClick={(e) => setNode(nodeDatum)}>
                  <rect x="105" y="30" width="19.0961" height="18.2431" rx="4.73846" fill="white" strokeWidth="0.4" stroke="#D2D2D2" />
                  <g transform="translate(109.8, 34)">
                    <path d="M5.76923 0.769231C5.76923 0.34375 5.42548 0 5 0C4.57452 0 4.23077 0.34375 4.23077 0.769231V4.23077H0.769231C0.34375 4.23077 0 4.57452 0 5C0 5.42548 0.34375 5.76923 0.769231 5.76923H4.23077V9.23077C4.23077 9.65625 4.57452 10 5 10C5.42548 10 5.76923 9.65625 5.76923 9.23077V5.76923H9.23077C9.65625 5.76923 10 5.42548 10 5C10 4.57452 9.65625 4.23077 9.23077 4.23077H5.76923V0.769231Z" fill="#D4D4D4" strokeWidth="0" />
                  </g>
                </g> */}
                <g>
                  <rect x="130" y="30" width="37.3439" height="18.65" rx="9.32501" fill="white" stroke="black" strokeWidth="0.2" />
                  <text
                    x="144"
                    y="43"
                    textAnchor="middle"
                    fill="#000"
                    strokeWidth="0"
                    fontFamily="Montserrat"
                    fontSize={10}
                    fontWeight={500}
                  >{nodeDatum.children.length}</text>
                  <g transform="translate(-75, -58)">
                    <path d="M234.902 96.9114L232.685 99.1286C232.644 99.1714 232.595 99.2055 232.54 99.229C232.485 99.2525 232.426 99.2648 232.367 99.2654C232.308 99.2659 232.249 99.2545 232.193 99.232C232.138 99.2095 232.088 99.1762 232.046 99.1342C232.004 99.0921 231.971 99.0421 231.949 98.9871C231.926 98.932 231.915 98.873 231.915 98.8136C231.916 98.7541 231.928 98.6953 231.952 98.6407C231.975 98.586 232.009 98.5366 232.052 98.4953L234.586 95.9614C234.67 95.8774 234.784 95.8303 234.902 95.8303C235.021 95.8303 235.135 95.8774 235.219 95.9614L237.753 98.4953C237.796 98.5366 237.83 98.586 237.853 98.6407C237.877 98.6953 237.889 98.7541 237.89 98.8136C237.89 98.873 237.879 98.932 237.856 98.9871C237.834 99.0421 237.801 99.0921 237.759 99.1342C237.716 99.1762 237.666 99.2095 237.611 99.232C237.556 99.2545 237.497 99.2659 237.438 99.2654C237.378 99.2648 237.32 99.2525 237.265 99.229C237.21 99.2055 237.161 99.1714 237.12 99.1286L234.902 96.9114Z" fill="black" strokeWidth="0" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </>
    );
  };

  return (
    <div className="flex flex-row space-x-4 w-full">
      <div className="w-full h-screen relative overflow-hidden">
        <Tree
          data={tree}
          zoomable={true}
          orientation="vertical"
          pathFunc="step"
          onNodeClick={handleNodeClick}
          translate={{
            x: 800,
            y: 100,
          }}
          nodeSize={{ x: 280, y: 150 }}
          separation={{ siblings: 1, nonSiblings: 1.5 }}
          renderCustomNodeElement={(nodeInfo) =>
            renderRectSvgNode(nodeInfo, handleNodeClick)
          }
        />
        {node && (
          <NodeModal
            onSubmit={(familyMemberName) => handleSubmit(familyMemberName)}
            onClose={close}
            isOpen={Boolean(node)}
          />
        )}
      </div>
      <NodeDetailsPanel isSidepanelOpen={isSidepanelOpen} setSidePanelOpen={setSidePanelOpen}/>
    </div>
  );
}
